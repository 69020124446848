// Require your JS files here,
// like so 'require('./header');'

require('./header');
require('./show-more-less');
require('./select2');
require('./gallery');
require('./free-gift');
require('./tooltips');
require('./pickups');
require('./product-options');
require('./bundel-slider');
require('./amasty-shopby-scroll-fix');
require('./sticky-product');
require('./compare');
