requirejs([
    'jquery'
],
function($) {
    $(document).ready(function() {
        if ($('body').hasClass('checkout-index-index')) {
            let self = this;
            $(document).on('change', '.additional-shipping-option-wrapper select', function() {
                let pickup = window.pickups ? window.pickups.find(p => {
                    return p.name === this.value;
                }) : false;

                window.pickup = pickup;

                if (pickup) {
                    $('#pickup-description').html(pickup.description_block);
                }
            });
        }
    });
});
