var variables = require(process.env.themeFolder);

requirejs([
    'jquery',
    'swiper',
    'fancybox'
],
function($, Swiper, fancybox) {
    $(document).ready(function () {
        if($(window).width() >= 768){
            var bundelSlider = new Swiper('.amrelated-pack-wrapper .amrelated-products-grid', {
                containerModifierClass: '.amrelated-products-grid',
                wrapperClass: 'amrelated-pack-list',
                slideClass: 'amrelated-pack-item',
                loop: false,
                slidesPerView: variables.bundelSlidesXs,
                slidesPerColumn: 1,
                spaceBetween: variables.bundelSlidesSpaceBetween,
                observer: true,
                observeParents: true,
                breakpointsInverse: true,

                breakpoints: {
                    [variables.xs]: {
                        slidesPerView: variables.bundelSlidesXs,
                    },

                    [variables.sm]: {
                        slidesPerView: variables.bundelSlidesSm,
                    },

                    [variables.md]: {
                        slidesPerView: variables.bundelSlidesMd,
                    },

                    [variables.lg]: {
                        slidesPerView: variables.bundelSlidesLg,
                    },

                    [variables.xl]: {
                        slidesPerView: variables.bundelSlidesXl,
                    }
                },

                // Navigation arrows
                navigation: {
                    nextEl: '.amrelated-pack-wrapper .swiper-button-next',
                    prevEl: '.amrelated-pack-wrapper .swiper-button-prev'
                }
            });
        }
    });
});
