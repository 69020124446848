require([
    'jquery',
],
function($) {
    $(document).ready(function() {
        $('.product-info-main .customizable-option').each(function() {
            $(this).find('.field .radio').first().click();
        });
    });
});
