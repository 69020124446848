var variables = require(process.env.themeFolder);

requirejs([
    'jquery',
    'swiper',
    'fancybox'
],
function($, Swiper, fancybox) {
    $(document).ready(function () {
        if ($('.images-wrapper').data('imagecount') > 0 && $('.gallery-thumbs').length) {
            var swiperSettings = {
                loop: variables.galleryThumbsLoop,
                spaceBetween: variables.galleryThumbsSpaceBetween,
                slidesPerView: variables.galleryThumbsSlidesPerView,
                freeMode: variables.galleryThumbsFreeMode,
                watchSlidesProgress: variables.galleryThumbsWatchSlidesProgress,
                slideToClickedSlide: true,
            };

            if (variables.galleryThumbsNavButtons == 'true') {
                $('.gallery-thumbs').append('<div class="swiper-button-next"></div><div class="swiper-button-prev"></div>');

                swiperSettings.navigation = {
                    nextEl: '.gallery-thumbs-wrapper .swiper-button-next',
                    prevEl: '.gallery-thumbs-wrapper .swiper-button-prev',
                };
            }
            var galleryThumbs = new Swiper('.gallery-thumbs', swiperSettings);
        }

        if ($('.images-wrapper').data('imagecount') > 0 && $('.gallery-top').length) {
            var galleryTopSettings =  {
                loop: variables.galleryTopLoop,
                spaceBetween: 0,
                centeredSlides: true,
                navigation: {
                    nextEl: '.gallery .swiper-button-next',
                    prevEl: '.gallery .swiper-button-prev',
                },
            };
            $('.gallery').addClass( 'swiper-transition' );

            if($('.images-wrapper').data('imagecount') < 2) {
                $('.swiper-wrapper').addClass( "disabled" );
                $('.swiper-pagination').addClass( "disabled" );
            }

            if (typeof galleryThumbs !== 'undefined' && variables.galleryThumbsUseControl == 'false') {
                galleryTopSettings.thumbs = {
                    swiper: galleryThumbs
                }
            }

            var galleryTop = new Swiper('.gallery-top', galleryTopSettings);

            /**
             * Closes Fancybox, if clicked outside element
             */
            $(document).on('click touchend', '.fancybox-slide', function (event) {
                if (!$(event.target).hasClass('fancybox-image')) {
                    $.fancybox.close();
                }
            });

            /**
             * Jump Swiper to current Fancybox-index, when closing Fancybox
             */
            $(document).on('beforeClose.fb', function () {
                var currentIndex = $.fancybox.getInstance().currIndex;
                galleryTop.slideTo(currentIndex);
            });

            if (variables.galleryThumbsUseControl == undefined || variables.galleryThumbsUseControl == 'true') {
                if (typeof galleryTop !== 'undefined') {
                    galleryTop.controller.control = galleryThumbs;
                }

                if (typeof galleryThumbs !== 'undefined') {
                    galleryThumbs.controller.control = galleryTop;
                }
            }
        }
    });
});
