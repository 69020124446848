var variables = require(process.env.themeFolder);

requirejs([
    'jquery', 'mage/translate'
],
function($, $t) {
    $('.show-more-less').click(function() {
        /** Toggle class 'expanded' for element with button's 'data-toggle' value class */
        $('.' + $(this).data('toggle')).toggleClass('expanded');

        /** Toggle 'expanded' class for button element and change text */
        if ($(this).toggleClass('expanded').hasClass('expanded')) {
            $(this).find('span').text($t('Show less'));
        } else {
            $(this).find('span').text($t('Show more'));
            let elementTop = $('.' + $(this).data('toggle')).first().offset().top;

            /** Scroll to top of list element if position is below element top */
            if ($('html, body').scrollTop() > elementTop) {
                $('html, body').scrollTop(elementTop);
            }
        }
    });

    let isMobile = $(window).width() <= variables.md;
    /** Show additional-attributes more/less button */
    if ($('.additional-attributes').prop('scrollHeight') > $('.additional-attributes').height()) {
        $('.additional-attributes-wrapper .actions-toolbar').show();
    }
});
