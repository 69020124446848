require([
    'jquery',
],
function($) {
    $(document).ready(function() {
        $('.checkout-cart-index .ampromo-items-add > a').on('click', function() {
            $('.checkout-cart-index .ampromo-overlay').toggleClass('active-gift');
            $('html').toggleClass('active-modal');
            return false;
        });

        $('.checkout-cart-index .ampromo-close').on('click', function() {
            $('.checkout-cart-index .ampromo-overlay').removeClass('active-gift');
            $('html').removeClass('active-modal');
        });
    });
});
