require([
    'jquery',
],
function($) {
    lastScroll = 0;
    var headerHeight =  $('.page-header').height(),
    scrollTop;

    $(window).on('scroll',function() {
        var scroll = $(window).scrollTop();
        if(lastScroll - scroll > 0) {
            $('body').addClass('scrolling-up');
        } else {
            $('body').removeClass('scrolling-up');
            $('body').removeClass('show-nav');
        }
        lastScroll = scroll;

        if (scroll <= 300) {
            $('body').addClass('almost-at-top');
        } else {
            $('body').removeClass('almost-at-top');
        }
    });

    $(document).ready(function() {
        $('.header-catalog').on('click', function() {
            $('body').toggleClass('show-nav');
            return false;
        });

        $(document).on('scroll', function(){
            var scrollTop = $(this).scrollTop();
            toggleStickyHeaderClass(scrollTop);
        });

        /**
         * Add or remove sticky class to body element
         */
        function toggleStickyHeaderClass(thisScrollTop) {
            if (thisScrollTop >= headerHeight && ! $('body').hasClass('sticky')) {
                $('body').removeClass('not-sticky').addClass('sticky');
            } else if (thisScrollTop < headerHeight ){
                $('body').removeClass('sticky').addClass('not-sticky');
            }
            headerHeight = $('.page-header').height() + 19 + 62;
        }
    });
});
