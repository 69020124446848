require([
        'jquery',
    ],
    function ($) {

        let tooltips = [
            {
                "button": ".checkout-cart-index .service-cost > .field > .label",
                "tooltip": ".checkout-cart-index .service-cost"
            },
            {
                "button": ".checkout-cart-index .product-insurance  > .field > .label",
                "tooltip": ".checkout-cart-index .product-insurance"
            },
            {
                "button": ".bicycleplan-label > span",
                "tooltip": ".bicycleplan-tooltip"
            }
        ];

        function openToolTip(tooltip) {
            $(tooltip).toggleClass('active-tooltip');
            $('html').toggleClass('active-modal');
        }

        function addTooltip(tooltip) {

            if ($(tooltip.button).length) {
                $(tooltip.button).on('click', () => openToolTip(tooltip.tooltip));
            } else {
                $('body').on('click', tooltip.button, () => openToolTip(tooltip.tooltip));
            }

            $('body').on('click', '.tooltip-close', () => {
                $(tooltip.tooltip).removeClass('active-tooltip');
                $('html').removeClass('active-modal');
            });
        }

        $(document).ready(function () {
            tooltips.forEach(addTooltip);
        });
    });
